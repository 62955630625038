import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { slugify } from '../../lib/utils'

const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  h4 {
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 0.5rem;
      a {
        font-family: Catamaran, sans-serif;
        text-decoration: none;
        font-size: 18px;
      }
    }
  }
`

const ClientList = ({ agent }) => {

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(sort: {fields: first_name}) {
        edges {
          node {
            contact_1_name {
              id
            }
            contact_2_name {
              id
            }
            contact_3_name {
              id
            }
            contact_4_name {
              id
            }
            first_name
            surname
          }
        }
      }
    }
  `)

  const all_artists = data.allSanityArtist.edges;

  const agent_artists = all_artists.filter(f => {
    if (f.node.contact_1_name?.id === agent) {
      return f
    } else if (f.node.contact_2_name?.id === agent) {
      return f
    } else if (f.node.contact_3_name?.id === agent) {
      return f
    } else if (f.node.contact_4_name?.id === agent) {
      return f
    } else {
      return
    }
  });

  if (agent_artists && agent_artists.length > 0) {

    return (
      <Container>
        <h4>Client list</h4>
        <ul>
          {agent_artists.map((a, index) => {
            const name = `${a.node.first_name} ${a.node.surname}`.trim();
            const url = '/artists/' + slugify(name);
            return (
              <li key={index}><Link to={url}>{name}</Link></li>
            )
          })}
        </ul>
      </Container>
    )

  } else {

    return (<></>);

  }
}

export default ClientList
