import React from 'react'
// import { PortableText } from '@portabletext/react'
import PortableText from 'react-portable-text'
import { Link } from 'gatsby'

const Portable = ({ blocks }) => {
  return (
    <>
      <PortableText
        content={blocks}
        serializers={{
          blockquote: (props) => {
            let fix_parts = [];
            const parts = props.children.map(c => {
              if (!c.hasOwnProperty('key')) {
                fix_parts.push(
                  c
                    .replaceAll('&amp;', '&')
                    .replaceAll('*****', '★★★★★')
                    .replaceAll('****', '★★★★')
                    .replaceAll('***', '★★★')
                    .replaceAll('**', '★★')
                );
              } else {
                fix_parts.push(c)
              }
              return c
            })
            return (
              <blockquote>{fix_parts}</blockquote>
            )
          },
          normal: (props) => {
            let fix_parts = [];
            const parts = props.children.map(c => {
              if (!c.hasOwnProperty('key')) {
                fix_parts.push(
                  c
                    .replaceAll('&amp;', '&')
                    .replaceAll('*****', '★★★★★')
                    .replaceAll('****', '★★★★')
                    .replaceAll('***', '★★★')
                    .replaceAll('**', '★★')
                );
              } else {
                fix_parts.push(c)
              }
              return c
            })
            return (
              <p>{fix_parts}</p>
            )
          },
          link: (props) => {
            let url;
            if (props.url !== undefined) {
              if (props.url.includes('pbj.pages.dev') || props.url.includes('pbjmanagement.co.uk')) {
                url = '/' + props.url.split('/')[3];
                return (
                  <Link to={url}>{props.children}</Link>
                )
              } else if (!props.url.includes('http')) {
                url = props.url;
                return (
                  <Link to={url}>{props.children}</Link>
                )
              } else {
                url = props.url;
                return (
                  <a href={props.url}>{props.children}</a>
                )
              }
            } else {
              return (
                <a href={props.children}>{props.children}</a>
              )
            }
          },
        }}
      />
    </>
  )
}

export default Portable;
