import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Person from '../components/person'

export const query = graphql`
  query PersonQuery($id: String!) {
    person: sanityPerson(id: { eq: $id }) {
      id
      title
      email
      phone
      _rawBiographyBlocks(resolveReferences: {maxDepth: 10})
      biography_blocks {
        _rawChildren
        _type
        list
        style
        children {
          _key
          _type
          text
          marks
        }
      }
      headshot {
        ...ImageWithPreview
        asset {
          publicUrl
        }
       }
    }
  }
`


const PersonTemplate = props => {

  const { data, errors } = props
  const gallery = props.pageContext.gallery;
  const person = data && data.person;

  return (
    <div>
      <Helmet
        bodyAttributes={{
          class: 'person-page'
        }}
      />
      {person && <Person {...person} />}
    </div>
  )
}

export default PersonTemplate
