import React from 'react'
import { blockContentToPlainText } from 'react-portable-text'
import styled from 'styled-components'
import Portable from '../portable'
import Title from '../title'
import { slugify } from '../../lib/utils'
import SEO from '../seo'
import ClientList from '../client_list'

const Main = styled.main`
  max-width: calc(1400px - 2rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 880px) {
    flex-direction: column;
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0;
    max-width: calc(100vw - 2rem);
  }
`

const Content = styled.div`
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(max-width: 880px) {
    a {
      display: block;
      text-align: center;
    }
    span {
      display: none;
    }
  }
`

const Contact = styled.div`
  margin-bottom: 2rem;
`

const Person = (props) => {

  const { title, email, phone, _rawBiographyBlocks, headshot } = props;
  const meta_image = headshot?.asset?.publicUrl || false
  const slug = '/people/' + slugify(title);
  const description = _rawBiographyBlocks ? blockContentToPlainText(_rawBiographyBlocks).split('.')[0] + '.' : false;

  return (
    <Main>
      <SEO title={title} description={description} image={meta_image} slug={slug} />
      <Title noborder>{title}</Title>
      <Content>
        <Contact><p><a href={`mailto:${email}`}>{email}</a> <span>&middot;</span> <a href={`tel:${phone}`}>{phone}</a></p></Contact>
        {_rawBiographyBlocks &&
          <Portable blocks={_rawBiographyBlocks} />
        }
        <ClientList agent={props.id} />
      </Content>
    </Main>
  )
}

export default Person;
